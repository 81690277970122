.bnpp-error-number-module-text {
    display: block;
    --fontSize: min(450px, 50vw);
    font-family: var(--wp--preset--font-family--bnpp-sans-condensed);
    font-size: var(--fontSize);
    line-height: 1;
    transform: translateY(10%);
    color: transparent;
    font-weight: 400;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: var(--wp--preset--color--bnpp-green);

    span:nth-child(2) {
        display: inline-block;
        transform: rotate(17deg);
    }
}